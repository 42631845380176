import PropTypes from 'prop-types';
import fromNow from 'fromnow';
import { DateTime } from 'luxon';

const PublishedDate = ({ date, showTime, relative, club }) => {
  if (!date) {
    return null;
  }

  const publishedLuxon = club
    ? DateTime.fromISO(date).setZone(club.timezone)
    : DateTime.fromISO(date);
  const now = DateTime.local();
  const daysAgo = now.diff(publishedLuxon, 'days');

  if (daysAgo.days < 7 && relative) {
    return fromNow(publishedLuxon.toISO(), { max: 1, suffix: true });
  }

  const time = showTime ? ' - HH:mm' : '';
  if (publishedLuxon.year === now.year) {
    return publishedLuxon.toFormat(`d MMM${time}`);
  }

  return publishedLuxon.toFormat(`d MMM yyyy${time}`);
};

PublishedDate.defaultProps = {
  date: null,
  showTime: false,
  relative: true,
  club: null,
};

PublishedDate.propTypes = {
  date: PropTypes.string,
  showTime: PropTypes.bool,
  relative: PropTypes.bool,
  club: PropTypes.shape({
    timezone: PropTypes.string,
  }),
};

export default PublishedDate;

import { identity, pickBy } from 'lodash';

class SocialShare {
  facebookShare = (url) => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, '', 'width=550,height=530');
  };

  twitterShare = (url, title) => {
    window.open(
      `https://twitter.com/intent/tweet?url=${url}${title ? `&text=${title}` : ''}`,
      '',
      'width=550,height=530',
    );
  };

  navigatorShare = async (title, text, url) => {
    const shareData = pickBy(
      {
        title,
        text,
        url,
      },
      identity,
    );

    try {
      await navigator.share(shareData);
      return true;
    } catch (e) {
      return false;
    }
  };
}

export default new SocialShare();

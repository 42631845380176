import React from 'react';
import { connect } from 'react-redux';
import Head from 'next/head';
import PropTypes from 'prop-types';
import { ImageResizer } from '@pitchero/react-ui';
import CLUB_PROP_TYPES from '../../lib/prop-types/club';
import { getCurrentClub } from '../../store/clubs/selectors';

const SocialHead = ({ club, url, title, type, description, imageUrl }) => {
  const facebookImageUrl =
    imageUrl !== null
      ? ImageResizer.resizeUrl(imageUrl, {
          w: 1200,
          h: 630,
          t: 'frame',
        })
      : null;

  const twitterImageUrl =
    imageUrl !== null
      ? ImageResizer.resizeUrl(imageUrl, {
          w: 288,
          h: 288,
          t: 'frame',
        })
      : null;

  return (
    <>
      <Head>
        <meta property="og:type" content={type} key="ogType" />
        <meta property="og:url" content={url} key="ogUrl" />
        <meta property="og:title" content={title} key="ogTitle" />
        {description && (
          <meta property="og:description" content={description} key="ogDescription" />
        )}

        {facebookImageUrl && (
          <>
            <meta property="og:image" content={facebookImageUrl} key="ogImage" />
            <meta property="og:image:width" content="1200" key="ogImageWidth" />
            <meta property="og:image:height" content="630" key="ogImageHeight" />
          </>
        )}

        <meta property="twitter:card" content="summary" key="twitterCard" />

        {club.twitterAccount && (
          <meta property="twitter:site" content={club.twitterAccount} key="twitterSite" />
        )}

        <meta property="twitter:title" content={title} key="twitterTitle" />
        {description && (
          <meta property="twitter:description" content={description} key="twitterDescription" />
        )}
        <meta property="twitter:url" content={url} key="twitterUrl" />

        {twitterImageUrl && (
          <meta property="twitter:image" content={twitterImageUrl} key="twitterImage" />
        )}
      </Head>
    </>
  );
};

SocialHead.defaultProps = {
  description: null,
  imageUrl: null,
  title: null,
  type: 'website',
};

SocialHead.propTypes = {
  club: CLUB_PROP_TYPES.isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  type: PropTypes.string,
};

const mapStateToProps = (state) => ({
  club: getCurrentClub(state),
});

export default connect(mapStateToProps, null)(SocialHead);

import React from 'react';
import PropTypes from 'prop-types';
import ActivityStreamItem from '../item';

const SponsoredContentMessage = ({ message, placement, ...otherProps }) => (
  <ActivityStreamItem
    imageSrc={message.image}
    linkProps={{
      route: 'news.sponsored',
      params: {
        id: message.content_id,
        _title: message.title,
        placement,
      },
    }}
    messageType={message.type}
    published={message.published}
    title={message.title}
    {...otherProps}
  />
);

SponsoredContentMessage.defaultProps = {
  placement: null,
};

SponsoredContentMessage.propTypes = {
  placement: PropTypes.string,
  message: PropTypes.shape({
    id: PropTypes.number.isRequired,
    content_id: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
    published: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default SponsoredContentMessage;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Space, Typography } from '@pitchero/react-ui';
import ShareIcon from '@pitchero/react-ui/dist/cjs/components/Icons/Share';
import IconWithTheme from '../icon-with-theme';
import { Trans } from '../../lib/i18n';
import SocialShare from '../../lib/social-share';
import SocialHead from './social-head';
import SharePanel from './share-panel';

const ShareButton = ({ color, iconSize, url, title, description, imageUrl, type, withText }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpenHandler = () => {
    if (navigator.share) {
      return SocialShare.navigatorShare(title, description, url);
    }

    return setIsOpen(!isOpen);
  };

  return (
    <>
      <SocialHead
        url={url}
        title={title}
        description={description}
        imageUrl={imageUrl}
        type={type}
      />
      <Button theme="text" onClick={onOpenHandler}>
        <span aria-label="share" role="button">
          <IconWithTheme>
            <ShareIcon size={iconSize} color={color} topOffset={2} />
          </IconWithTheme>
          {withText && (
            <Space left="xsmall">
              <Typography preset="button" color={color}>
                <Trans i18nKey="common:share">Share</Trans>
              </Typography>
            </Space>
          )}
        </span>
      </Button>
      <SharePanel
        url={url}
        title={title}
        imageUrl={imageUrl}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
};

ShareButton.defaultProps = {
  color: 'primary',
  description: null,
  iconSize: 16,
  imageUrl: null,
  withText: false,
  title: null,
  type: 'website',
};

ShareButton.propTypes = {
  color: PropTypes.string,
  url: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  iconSize: PropTypes.number,
  type: PropTypes.string,
  withText: PropTypes.bool,
};

export default ShareButton;

import React from 'react';
import PropTypes from 'prop-types';
import AlbumMessage from './album';
import ClubEventMessage from './club-event';
import MatchPreviewMessage from './match-preview';
import MatchReportMessage from './match-report';
import NewsMessage from './news';
import SponsoredContentMessage from './sponsored-content';
import TeamSelectionMessage from './team-selection';
import VideoMessage from './video';
import DaznEPlayer from './dazn-e-player';

const ActivityStreamMessage = ({ message, placement, stacked, style, ...otherProps }) => {
  switch (message.type) {
    case 'album':
      return <AlbumMessage message={message} stacked={stacked} style={style} {...otherProps} />;
    case 'club_event':
      return <ClubEventMessage message={message} stacked={stacked} style={style} {...otherProps} />;
    case 'match_preview':
      return (
        <MatchPreviewMessage message={message} stacked={stacked} style={style} {...otherProps} />
      );
    case 'match_report':
      return (
        <MatchReportMessage message={message} stacked={stacked} style={style} {...otherProps} />
      );
    case 'news':
      return <NewsMessage message={message} stacked={stacked} {...otherProps} />;
    case 'sponsored_content':
      return (
        <SponsoredContentMessage
          message={message}
          placement={placement}
          stacked={stacked}
          {...otherProps}
        />
      );
    case 'team_selection':
      return (
        <TeamSelectionMessage message={message} stacked={stacked} style={style} {...otherProps} />
      );
    case 'video':
      return <VideoMessage message={message} stacked={stacked} style={style} {...otherProps} />;
    case 'dazn_player':
      return <DaznEPlayer message={message} stacked={stacked} style={style} {...otherProps} />;
    default:
      return null;
  }
};

ActivityStreamMessage.defaultProps = {
  placement: null,
  shadow: true,
  stacked: 'always',
  style: {},
};

ActivityStreamMessage.propTypes = {
  stacked: PropTypes.string,
  shadow: PropTypes.bool,
  message: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
  placement: PropTypes.string,
  style: PropTypes.shape(),
};

export default ActivityStreamMessage;

import React from 'react';
import PropTypes from 'prop-types';
import ActivityStreamItem from '../item';

const ClubEventMessage = ({ message, ...otherProps }) => (
  <ActivityStreamItem
    imageSrc={message.image}
    linkProps={{
      route: 'calendar.event',
      params: { id: message.event_id, _title: message.title },
    }}
    messageType={message.type}
    title={message.title}
    {...otherProps}
  />
);

ClubEventMessage.propTypes = {
  message: PropTypes.shape({
    event_id: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default ClubEventMessage;

import React from 'react';
import PropTypes from 'prop-types';
import ActivityStreamItem from '../item';

const NewsMessage = ({ message, ...otherProps }) => (
  <ActivityStreamItem
    imageSrc={message.image}
    linkProps={{
      route: 'news.article',
      params: { id: message.article_id, _title: message.title },
    }}
    messageType={message.type}
    published={message.published}
    title={message.title}
    tagline={message.tagline}
    author={message.author?.name}
    {...otherProps}
  />
);

NewsMessage.propTypes = {
  message: PropTypes.shape({
    article_id: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
    published: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    tagline: PropTypes.string.isRequired,
    author: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default NewsMessage;

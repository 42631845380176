import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Cushion, Grid, Rectangle, RelativeContainer, Space, Typography } from '@pitchero/react-ui';
import VideoIcon from '@pitchero/react-ui/dist/cjs/components/Icons/Video';
import IconWithTheme from '../../icon-with-theme';
import MediaItem from '../../media-item';
import { Trans } from '../../../lib/i18n';
import SixteenByNineFrame from '../../video/sixteen-by-nine-frame';

const DaznEPlayer = ({ message, stacked }) => {
  const videoRef = useRef();
  useEffect(() => {
    const scriptEl = document.createElement('script');
    scriptEl.src = `//player.daznservices.com/player.js#21205da4208e48245df5543dae.rywmih1gu61g1r2v2f4e59w8q$dfchid=${message.id}`;
    scriptEl.async = true;
    videoRef.current.appendChild(scriptEl);
  }, []);

  return (
    <Rectangle fill="white" radius="rounded" style={{ cursor: 'pointer' }}>
      <MediaItem
        stacked={stacked}
        className="cardHighlight"
        renderImage={() => (
          <RelativeContainer>
            <Rectangle
              radius={stacked === 'always' ? 'roundedTop' : 'rounded'}
              responsive={[
                {
                  minWidth: 'tab',
                  props: { radius: stacked === 'never' ? 'rounded' : 'roundedTop' },
                },
              ]}
              style={{ overflow: 'hidden' }}
            >
              {message.id && (
                <SixteenByNineFrame>
                  <Rectangle fill="alabaster">
                    <Grid columns="1fr" style={{ height: '100%' }}>
                      <div
                        className="home-page-player-wrapper"
                        style={{ gridColumn: 1, gridRow: 1, zIndex: 2 }}
                      >
                        <div className="dazn-video-container" ref={videoRef} />
                      </div>
                      <div
                        style={{
                          alignSelf: 'center',
                          justifySelf: 'center',
                          gridColumn: 1,
                          gridRow: 1,
                          zIndex: 1,
                        }}
                      >
                        <IconWithTheme>
                          <VideoIcon colorOne="mercury" colorTwo="alabaster" size={60} />
                        </IconWithTheme>
                      </div>
                    </Grid>
                  </Rectangle>
                </SixteenByNineFrame>
              )}
            </Rectangle>
          </RelativeContainer>
        )}
        renderBody={() => (
          <>
            <Space
              bottom={stacked === 'always' ? 'small' : 'xsmall'}
              responsive={[{ minWidth: 'tab', props: { bottom: 'small' } }]}
            >
              <Cushion horizontal="xsmall" vertical={2}>
                <Rectangle fill="primary" radius="rounded" inline>
                  <Typography
                    color="primaryContrast"
                    component="div"
                    family="montserrat"
                    size="11px"
                    transform="uppercase"
                    weight="bold"
                  >
                    <Trans i18nKey="common:video_highlights_card_header">Video highlights</Trans>
                  </Typography>
                </Rectangle>
              </Cushion>
            </Space>

            <Typography
              component="div"
              preset={stacked === 'never' ? 'subtitle--small' : 'subtitle--large'}
              color="black"
            >
              {message.title}
            </Typography>
          </>
        )}
      />
    </Rectangle>
  );
};

DaznEPlayer.defaultProps = {
  stacked: null,
};

DaznEPlayer.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    content_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    image: PropTypes.string.isRequired,
    published: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  stacked: PropTypes.string,
};

export default DaznEPlayer;

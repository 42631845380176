import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { orderBy, reduce } from 'lodash';
import { getAdvertsSetup } from '../../store/page-info/selectors';

const AdvertContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  ${(props) => {
    const { responsiveSizes, size } = props;

    if (!responsiveSizes) {
      return Array.isArray(size) && size.length === 2 && size[1] !== 1
        ? `min-height: ${size[1]}px;`
        : '';
    }

    const responsiveHeights = responsiveSizes.map((responsiveSize) => {
      const { breakpoint, sizes } = responsiveSize;
      if (!Array.isArray(sizes)) {
        return null;
      }

      const [breakpointMinWidth, breakpointMinHeight] = breakpoint;
      if (!Array.isArray(sizes[0])) {
        return {
          breakpointMinWidth,
          breakpointMinHeight,
          largestHeight: sizes[1],
        };
      }

      const sizeHeights = responsiveSize.sizes.map((sizeArray) =>
        Array.isArray(sizeArray) && sizeArray.length === 2 ? sizeArray[1] : 0,
      );

      return {
        breakpointMinWidth,
        breakpointMinHeight,
        largestHeight: Math.max(...sizeHeights),
      };
    });

    return reduce(
      orderBy(responsiveHeights, ['largestHeight'], ['asc']),
      (css, responsiveHeight) => {
        if (!responsiveHeight) {
          return css;
        }
        const { breakpointMinWidth, breakpointMinHeight, largestHeight } = responsiveHeight;

        return `
          ${css}
          @media screen and (min-width: ${breakpointMinWidth}px) and (min-height: ${breakpointMinHeight}px) {
            min-height: ${largestHeight}px;
          };
        `;
      },
      '',
    );
  }}
`;

const Advert = ({ adConfig, advertsSetup, className, style: customStyle }) => {
  if (!adConfig) return null;

  const ref = useRef();
  const { id } = adConfig;

  useEffect(
    () =>
      function cleanup() {
        if (ref && ref.current) {
          ref.current.innerHTML = '';
        }

        // If there is a skin remove that from the DOM also
        const skinContainers = document.getElementsByClassName('mm-skin-container');
        while (skinContainers[0]) {
          skinContainers[0].parentNode.removeChild(skinContainers[0]);
          const skinInner = document.getElementsByClassName('js-skins-container-inner');
          if (skinInner[0]) {
            skinInner[0].style.width = 'auto';
          }
        }
      },
    [],
  );

  useEffect(() => {
    if (advertsSetup) {
      window.googletag = window.googletag || {};
      window.googletag.cmd = window.googletag.cmd || [];
      window.googletag.cmd.push(() => {
        window.googletag.display(id);
      });
    }
  }, [advertsSetup]);

  return (
    <AdvertContainer
      className={className}
      responsiveSizes={adConfig.responsiveSizes}
      size={adConfig.size}
      style={customStyle}
    >
      <div
        className="js-ad"
        id={id}
        ref={ref}
        style={{ overflow: 'hidden', margin: '0 auto', maxWidth: '100%' }}
      />
    </AdvertContainer>
  );
};

Advert.propTypes = {
  adConfig: PropTypes.shape().isRequired,
  advertsSetup: PropTypes.bool.isRequired,
  className: PropTypes.string,
  style: PropTypes.shape(),
};
Advert.defaultProps = {
  className: null,
  style: {},
};

export default connect((state) => ({ advertsSetup: getAdvertsSetup(state) }))(Advert);
